.container{
  height: 100vh;
  padding: 1rem;
  overflow-y: auto;
}

.filtersseSelect {
  width: 100%;
}

.addButton {
  background-color: var(--green) !important;
  color: white !important;
  transition: background-color 0.2s;
}
