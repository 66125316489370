.container {
  height: 100vh;
  padding: 1rem;
  overflow-y: auto;
}

.goBackContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  max-width: 200px;
}

.goBackContainer:hover .goBackIcon {
  color: var(--green);
  stroke: var(--green);
}

.goBackContainer:hover .goBackTitle {
  color: var(--green);
}

.goBackIcon {
  font-size: 18px;
  color: #2b2b2b;
  stroke-width: 30;
  stroke: #2b2b2b;
  padding: 5px;
  transition: all 200ms;
}

.goBackTitle {
  margin: 0;
  font-weight: bold;
  color: #2b2b2b;
  transition: all 200ms;
}

.infosContainer {
  padding: calc(1rem + 20px);
}


.statusContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 2px solid #F1F1EF;
  border-right: 2px solid #F1F1EF;
}

.statusContent {
  width: 100%;
  padding: 0 4rem;
}

.customerInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-left: 10px;
}

.paymentContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1
}

.ratingContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1
}

.ratings {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.ratingStars {
  color: #97D729;
  margin-left: 5px;
}

.commentsContainer {
  height: 80px;
  width: 300px;
  overflow: scroll;
}

.totalPaymentContainer,
.rowContainer {
  display: flex;
  flex: 5;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 calc(1rem + 20px);
  width: 100%;
}

.title {
  font-weight: bold;
  font-size: 24px;
  margin: 0;
  color: #2b2b2b;
}

.text,
.textAlignRight,
.totalText {
  color: #2b2b2b;
  margin: 0;
  font-weight: 500;
}

.textAlignRight {
  text-align: right;
}

.kiwiCreditsText {
  text-align: right;
  color: #ff9900;
}

.divider {
  min-height: 150px;
  width: 2px;
  background-color: #F1F1EF;
}

.horizontalDivider {
  width: 98%;
  height: 2px;
  background-color: #F1F1EF;
  margin: 1rem;
}

.valuesContainer {
  display: flex;
  padding-top: 1rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 200px;
}

.valueRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.totalPaymentContainer {
  justify-content: flex-end;
  gap: 3rem;
  padding-bottom: 1rem;
}

.driverRowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.editIcon {
  height: 15px;
  width: 15px;
  cursor: pointer;
}

.spinContainer {
  height: 90vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.csvButton {
  margin-top: 1rem;
}

.editWindowButton {
  display: flex;
  align-self: flex-start;
  margin: 1rem 7rem;
}

.buttonsRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px 50px;
  margin-bottom: 10px;
}

.button {
  background-color: var(--green) !important;
  width: 9rem;
  color: white !important;
  transition: background-color 0.2s;
}

.disabled {
  width: 9rem;
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  transition: background-color 0.2s;
}

.routeIdContainer {
  display: flex;
  height: auto;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.goToDetailsButton {
  margin-right: 2px;
  height: 10px;
  width: 10px;
  cursor: pointer;
}

@media only screen and (max-width: 992px) {

  .statusContainer,
  .customerInfoContainer {
    align-items: flex-start;
  }

  .statusContainer {
    border-left: none;
    border-right: none;
    border-top: 2px solid #F1F1EF;
    border-bottom: 2px solid #F1F1EF;
    margin: 1rem 0;
    padding: 1rem 0;
  }

  .statusContent {
    padding-left: 0;
  }
}
